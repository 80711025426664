.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #3f51b5;
}
.ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    margin:auto;
}
.ant-steps-item-title{
    font-size: 14px;
}
.ant-steps-item-description{
    font-size: 12px;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #3f51b5;
    margin: 0;
}
.ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 120px;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #3f51b5;
    border-color: #3f51b5;
}
.ant-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #3f51b5;
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    content: '';
}
 .ant-btn:hover, .ant-btn:focus {
   border-color: #3f51b5;
}
 .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    margin: 0 0 0 60px;
}


@media(max-width:320px) {
    .ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
        width: 105px;
    }
    .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
        left: 1px;
    }
    .ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
        left: -6px;
    }
}